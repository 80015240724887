<script setup>
import DataTable from "@/components/common/DataTable.vue"
import { showNotification } from "@/composables/useNotification"
import { useOffersStore } from "@/stores/Offers"
import { useProductStore } from "@/stores/productStore"
import { computed, onMounted, ref, reactive } from "vue"
import { useAuth0 } from "@auth0/auth0-vue"

const { getAccessTokenSilently, user } = useAuth0();
const offersStore = useOffersStore();
const productStore = useProductStore();
const billerURL = process.env.VUE_APP_BILLER_URL;

const newOffer = reactive({
  productId: '',
  price: ''
});

const showModal = ref(false);
const isLoading = ref(true);

const titlesTable = computed(() => [
  {
    width: "w-3/12",
    title: "Producto",
  },
  {
    width: "w-2/12",
    title: "Precio Original",
  },
  {
    width: "w-2/12",
    title: "Precio Oferta",
  },
  {
    width: "w-3/12",
    title: "Link de Oferta",
  },
  {
    width: "w-2/12",
    title: "",
  },
]);

onMounted(async () => {
  const token = await getAccessTokenSilently({
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  });
  const userId = user.value.sub;
  localStorage.setItem("token", token);

  await offersStore.getOffers();
  await productStore.getAllProducts(userId);
  isLoading.value = false;
});

const copyLink = (uuid) => {
  const link = `${billerURL}/checkout/${uuid}`
  navigator.clipboard.writeText(link).then(() => {
    showNotification("Link copiado", "success");
  }).catch(err => {
    console.error('Failed to copy link: ', err);
  });
}

const goToLink = (uuid) => {
  const link = `${billerURL}/checkout/${uuid}`
  window.open(link, '_blank');
}

const createOffer = async () => {
  if (!newOffer.productId || newOffer.price === '') {
    showNotification("Por favor, complete todos los campos", "error");
    return;
  }

  try {
    await offersStore.createOffer({
      product: newOffer.productId,
      price: parseFloat(newOffer.price)
    });
    showNotification("Oferta creada exitosamente", "success");
    newOffer.productId = '';
    newOffer.price = '';
    showModal.value = false;
    await offersStore.getOffers();
  } catch (error) {
    console.error("Error creating offer:", error);
    showNotification("Error al crear la oferta", "error");
  }
}

const deleteOffer = async (uid) => {
  try {
    await offersStore.deleteOffer(uid);
    showNotification("Oferta eliminada exitosamente", "success");
    await offersStore.getOffers();
  } catch (error) {
    console.error("Error deleting offer:", error);
    showNotification("Error al eliminar la oferta", "error");
  }
}

const products = computed(() => 
  (productStore.listProducts.products || []).filter(product => product.status === "aceptado")
);
</script>

<template>
  <section>
    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.OFFERS") }}</h2>
        <button @click="showModal = true" class="btn btn-dark">
          <i class="fas fa-plus"></i> Agregar nueva oferta
        </button>
      </div>
    </section>

    <!-- Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h3 class="modal-title">Nueva Oferta</h3>
        <div class="form-group">
          <label>Producto:</label>
          <select v-model="newOffer.productId" class="form-control">
            <option value="">Seleccione un producto</option>
            <option v-for="product in products" :key="product.id" :value="product.id">
              {{ product.title }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Precio:</label>
          <input v-model="newOffer.price" type="number" step="0.01" class="form-control" placeholder="Ingrese el precio">
        </div>
        <div class="modal-actions">
          <button @click="createOffer" class="btn btn-dark">Crear Oferta</button>
          <button @click="showModal = false" class="btn btn-outline-danger">Cancelar</button>
        </div>
      </div>
    </div>

    <DataTable :noHaveData="offersStore?.data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in offersStore?.data" :key="index">
          <td class="p-3" v-text="item.product.title || '-'" />
          <td class="p-3" v-text="item.product.price || '-'" />
          <td class="p-3" v-text="item.price || '-'" />
          <td class="p-3">
            <button class="btn bg-green text-white mr-4" @click="goToLink(item.uid)">
              Ir a oferta
            </button>
            <button class="btn bg-copy text-white" @click="copyLink(item.uid)">
              <i class="fas fa-copy"></i>
            </button>
          </td>
          <td class="p-3 deleteButton">
            <button class="btn btn-outline-danger" @click="deleteOffer(item.uid)">
              Eliminar oferta
            </button>
          </td>
        </tr>
      </template>
    </DataTable>
  </section>
</template>

<style scoped>
.mr-4{
  margin-right: 10px;
}
.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.bg-copy{
  background-color: transparent;
  border: 1px solid #ccc;
}
.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
}
.bg-copy:hover , .bg-copy:hover i{
  background-color: #22C55E;
}
.btn-success {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
}

.btn-outline-secondary {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid #6c757d;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 80%;
  max-width: 500px;
}

.modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}
.bg-copy i{
  color: #22C55E;
}
.mr-4 {
  margin-right: 1rem;
}
.deleteButton{
  align-items: start;
}
</style>